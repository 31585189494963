import React from "react";
import adobe from "../assets/aboutIcons/Adobe.png";
import figma from "../assets/aboutIcons/Figma.png";
import sports from "../assets/aboutIcons/Sports.png";
import cooking from "../assets/aboutIcons/Cooking.png";
import videoGames from "../assets/aboutIcons/SuperMario.png";
import { AiOutlineClose } from "react-icons/ai";

const About = ({ aboutIsOpen, setAboutIsOpen }) => {
  const handleAboutIsOpen = () => {
    !aboutIsOpen ? setAboutIsOpen(true) : setAboutIsOpen(false);
  };
  return (
    <div className="about-container">
      <div className="AboutContainer" id="about">
        <div>
          <h2>Hey!</h2>
          <br />
          <p>
            I am Bryan, a Digital Product Designer with experience as a
            freelancer, in a start-up’s and multitasking performance 😜. I
            create websites and mobile experiences for projects related to
            technology, e-commerce and lifestyle. I help businesses to reach
            their goals by creating clean but catchy digital solutions that have
            a personality.{" "}
          </p>
        </div>

        <button
          className={`about-read-more-button ${!aboutIsOpen && "close"}`}
          onClick={handleAboutIsOpen}
        >
          Read more
        </button>
        <button
          onClick={handleAboutIsOpen}
          className={`about-close-button ${aboutIsOpen && "close"}`}
        >
          <AiOutlineClose
            style={{ scale: "1.6", color: "var(--primary-color)" }}
          />
        </button>
      </div>
      <div className={`about-extras-container ${aboutIsOpen ? "open" : ""}`}>
        <div className="about-extra">
          <h3>Skills:</h3>
          <p>
            <img src={adobe} alt="Adobe" />
            Adobe
          </p>
          <p>
            <img src={figma} alt="Figma" />
            Figma
          </p>
        </div>
        <div className="about-extra">
          <h3>Hobbies:</h3>
          <p>
            <img src={sports} alt="Sports" />
            Sports
          </p>
          <p>
            <img src={cooking} alt="Sports" />
            Cooking
          </p>
          <p>
            <img src={videoGames} alt="Sports" />
            Video Games
          </p>
        </div>
        <div className="about-extra">
          <h3>Language:</h3>
          <p>🇪🇸 Spanish (Native)</p>
          <p>🇬🇧 English</p>
        </div>
      </div>
    </div>
  );
};

export default About;
