import React, { useState } from "react";
import Footer from "./components/Footer";
import About from "./pages/About";
import Hero from "./pages/Hero";
import Projects from "./pages/Projects";

function App() {
  const [aboutIsOpen, setAboutIsOpen] = useState(false);

  return (
    <div>
      <Hero />
      <About aboutIsOpen={aboutIsOpen} setAboutIsOpen={setAboutIsOpen} />
      <Projects aboutIsOpen={aboutIsOpen} />
      <Footer />
    </div>
  );
}

export default App;
