import React from "react";
import Navbar from "../components/Navbar";

const Hero = () => {
  return (
    <div>
      <Navbar />
      <div className="HeroContainer">
        <h1>
          Designing <br /> web projects <br />
          <span style={{ color: "var(--secondary-color)" }}>from roots</span>
        </h1>
      </div>
    </div>
  );
};

export default Hero;
