import React from "react";

const Footer = () => {
  const today = new Date();
  return (
    <div className="FooterContainer" id="contact">
      <div className="footer-talk">
        <a href="mailto:bryangr980310@gmail.com" className="footer-talk-text">
          Let's talk
        </a>
      </div>
      <div className="footer-links">
        <a
          href="https://www.behance.net/bryanrestrepo"
          target="_blank"
          rel="noreferrer"
        >
          Behance
        </a>
        <p>&copy;&nbsp;{today.getFullYear()}</p>
        <a
          href="https://www.linkedin.com/in/bryan-giraldo-restrepo-9522521a4"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
      </div>
    </div>
  );
};

export default Footer;
