import React, { useEffect } from "react";
import linkIcon from "../assets/linkIcon.svg";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import SwiperCore, {
  Navigation,
  Thumbs,
  Pagination,
  Controller,
  Autoplay,
  A11y,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Controller, Thumbs, Autoplay]);

const customStyles = {
  content: {
    top: "5vh",
    left: "5vw",
    right: "auto",
    bottom: "auto",
    margin: "0",
    width: "90vw",
    height: "90vh",
    border: "none",
    transition: ".5s all ease-in",
    zIndex: "10000",
    backgroundColor: "#efefef",
    padding: "5vh 5vw",
  },
};

Modal.setAppElement("#root");

const Project = ({
  isOpen,
  closeModal,
  img1,
  img2,
  img3,
  img4,
  img5,
  title,
  subTitle,
  tag1,
  tag2,
  tag3,
  description,
  link,
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={title}
    >
      {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
      <div className="project-modal">
        <div className="close-btn" onClick={closeModal}>
          <AiOutlineClose
            style={{ scale: "1.6", color: "var(--primary-color)" }}
          />
        </div>
        <div className="project-modal-content">
          <div className="project-modal-content-descritpion">
            <h2>{title}</h2>
            <p className="sub-title">{subTitle}</p>
            <div className="tags-contenitor">
              <p className="tags">{tag1}</p>
              {tag2 && <p className="tags">{tag2}</p>}
              {tag3 && <p className="tags">{tag3}</p>}
            </div>
            <p className="description">{description}</p>
            {link && (
              <a href={link} target="_blank" rel="noreferrer">
                <img src={linkIcon} alt="link to espacio continuo" />
                <span>VISIT PROJECT</span>
              </a>
            )}
          </div>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={true}
            className="modal-swiper"
          >
            <SwiperSlide>
              <img src={img1} alt={title} className="modal-swiper-img" />
            </SwiperSlide>
            {img2 && (
              <SwiperSlide>
                <img src={img2} alt={title} className="modal-swiper-img" />
              </SwiperSlide>
            )}
            {img3 && (
              <SwiperSlide>
                <img src={img3} alt={title} className="modal-swiper-img" />
              </SwiperSlide>
            )}
            {img4 && (
              <SwiperSlide>
                <img src={img4} alt={title} className="modal-swiper-img" />
              </SwiperSlide>
            )}
            {img5 && (
              <SwiperSlide>
                <img src={img5} alt={title} className="modal-swiper-img" />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
    </Modal>
  );
};

export default Project;
