import React, { useState } from "react";
import arrow from "../assets/Arrow.png";
// ESD COLOMBIA IMGS
import esdColombia from "../assets/esdColombia.png";
import esd1 from "../assets/esdColombia/esd1.png";
import esd2 from "../assets/esdColombia/esd2.png";
import esd3 from "../assets/esdColombia/esd3.png";
// ESPACIO CONTINUO IMGS
import espacioContinuo from "../assets/espacioContinuo.png";
import esp1 from "../assets/Espacio Continuo/esp1.png";
import esp2 from "../assets/Espacio Continuo/esp2.png";
import esp3 from "../assets/Espacio Continuo/esp3.png";
// SMART RENT IMGS
import smartRent from "../assets/smartRent.png";
import sr1 from "../assets/SmartRent/sr1.png";
import sr2 from "../assets/SmartRent/sr2.png";
// HERRAGRO IMGS
import herragro from "../assets/herragro.png";
import h1 from "../assets/Herragro/h1.png";
import h2 from "../assets/Herragro/h2.png";
import h3 from "../assets/Herragro/h3.png";
// BRIDGE BAKER IMGS
import bridgeBaker from "../assets/bridgeBaker.png";
import bb1 from "../assets/Bridge Baker/bb1.png";
import bb2 from "../assets/Bridge Baker/bb2.png";
// MORE IMGS
import more from "../assets/more.png";
import m1 from "../assets/More/m1.jpg";
import m2 from "../assets/More/m2.png";
import m3 from "../assets/More/m3.png";
import m4 from "../assets/More/m4.jpg";
import m5 from "../assets/More/m5.png";
import Project from "../components/Project";

const Projects = ({ aboutIsOpen }) => {
  // MODAL ESPACIO CONTINUO
  const [espacioContinuoIsOpen, setEspacioContinuoIsOpen] = useState(false);

  function openEspacioContinuo() {
    setEspacioContinuoIsOpen(true);
  }

  function closeEspacioContinuo() {
    setEspacioContinuoIsOpen(false);
  }
  // MODAL ESD COLOMBIA
  const [esdColombiaIsOpen, setEsdColombiaIsOpen] = useState(false);

  function openEsdColombia() {
    setEsdColombiaIsOpen(true);
  }

  function closeEsdColombia() {
    setEsdColombiaIsOpen(false);
  }
  // MODAL SMART RENT
  const [smartRentIsOpen, setSmartRentIsOpen] = useState(false);

  function openSmartRent() {
    setSmartRentIsOpen(true);
  }

  function closeSmartRent() {
    setSmartRentIsOpen(false);
  }
  // MODAL HERRAGRO
  const [herragroIsOpen, setHerragroIsOpen] = useState(false);

  function openHerragro() {
    setHerragroIsOpen(true);
  }

  function closeHerragro() {
    setHerragroIsOpen(false);
  }
  // MODAL BRIDGE BAKER
  const [bridgeBakerIsOpen, setBridgeBakerIsOpen] = useState(false);

  function openBridgeBaker() {
    setBridgeBakerIsOpen(true);
  }

  function closeBridgeBaker() {
    setBridgeBakerIsOpen(false);
  }
  // MODAL MORE
  const [moreIsOpen, setMoreIsOpen] = useState(false);

  function openMore() {
    setMoreIsOpen(true);
  }

  function closeMore() {
    setMoreIsOpen(false);
  }
  return (
    <div
      className={`ProjectsContainer ${aboutIsOpen ? "" : "noMargin"}`}
      id="projects"
    >
      <h3>
        Projects <br />
        <img src={arrow} alt="Arrow" style={{ margin: "10px 5px" }} />
      </h3>

      <div
        className="project"
        style={{ background: `url("${esdColombia}")` }}
        // onClick={handleProjectModalEsdColombia}
        onClick={openEsdColombia}
      >
        <div>
          <p className="tags">UX/UI</p>
          <p className="tags">ICON</p>
        </div>
        <h3>ESD Colombia</h3>
      </div>
      <div
        className="project"
        style={{ background: `url("${espacioContinuo}")` }}
        onClick={openEspacioContinuo}
      >
        <div>
          <p className="tags">UX/UI</p>
        </div>
        <h3>Espacio Continuo</h3>
      </div>
      <div
        className="project"
        style={{
          background: `url("${smartRent}")`,
          gridArea: "2 / 1 / 3 / 3",
          aspectRatio: "auto",
        }}
        onClick={openSmartRent}
      >
        <div>
          <p className="tags">UX/UI</p>
        </div>
        <h3>Smart Rent</h3>
      </div>
      <div
        className="project"
        style={{
          background: `url("${herragro}")`,
        }}
        onClick={openHerragro}
      >
        <div>
          <p className="tags">UX/UI</p>
        </div>
        <h3>Herragro</h3>
      </div>
      <div
        className="project"
        style={{
          background: `url("${bridgeBaker}")`,
        }}
        onClick={openBridgeBaker}
      >
        <div>
          <p className="tags">UX/UI</p>
        </div>
        <h3>Bridge Baker</h3>
      </div>
      <div
        className="project"
        style={{
          background: `url("${more}")`,
          gridArea: "3 / 2 / 3 / 4",
          aspectRatio: "auto",
        }}
        onClick={openMore}
      >
        <div>
          <p className="tags">Flyers</p>
          <p className="tags">Logo Design</p>
          <p className="tags">Branding</p>
          <p className="tags">Photo Editing</p>
        </div>
        <h3>& more ...</h3>
      </div>

      {/* ESPACIO CONTINUO */}
      <Project
        isOpen={espacioContinuoIsOpen}
        closeModal={closeEspacioContinuo}
        img1={esp1}
        img2={esp2}
        img3={esp3}
        title="Espacio Continuo"
        subTitle="Art Gallery (Bogotà, Colombia)"
        tag1="UX/UI"
        description="The informative web design for a modern, minimalist art gallery in Bogota should have a clean and elegant aesthetic that reflects the gallery's style. The website should provide visitors with comprehensive information about the gallery's exhibitions, artists, and events, as well as high-quality images of the artworks on display. The navigation should be intuitive and easy to use, with clear sections for different types of content. The website should also include a blog section with engaging content related to art and the gallery's activities."
        whatIsTheClientLookingFor1="A clean design, easy to look at and interpret."
        whatIsTheClientLookingFor2="Lots of visuals and less text."
        whatIsTheClientLookingFor3="Soft colours, pleasing to the eye."
        whatIsTheClientLookingFor4="Minimalism."
        whatIsTheClientLookingFor5="Delicate animations."
        link="https://www.espaciocontinuo.co/en/"
      />
      {/* ESD COLOMBIA */}
      <Project
        isOpen={esdColombiaIsOpen}
        closeModal={closeEsdColombia}
        img1={esd1}
        img2={esd2}
        img3={esd3}
        title="ESD COLOMBIA"
        subTitle="E-Commerce"
        tag1="UX/UI"
        tag2="ICON"
        description="The e-commerce design for selling cannabis accessories should have a modern and stylish aesthetic, modern typography, bright colors, and engaging imagery to create an attractive and memorable shopping experience. The website should be optimised for mobile devices, with responsive design that ensures a seamless experience across all screen sizes.
        The navigation should be intuitive and easy to use, with clear categorisation and product filtering options to help customers find the products they need.
        The checkout process should be streamlined and secure, with fast loading times and multiple payment options to maximise convenience and customer satisfaction."
        link="https://esdcolombia.com/"
      />
      {/* SMART RENT */}
      <Project
        isOpen={smartRentIsOpen}
        closeModal={closeSmartRent}
        img1={sr1}
        img2={sr2}
        title="Smart Rent"
        subTitle="E-Commerce"
        tag1="UX/UI"
        description="The B2B e-commerce platform for renting and selling electronic devices should have a user-friendly interface with easy navigation and clear categorisation of products. The website should provide detailed product information, high-quality images, and customer reviews to help customers make informed decisions. The checkout process should be simple and secure, with multiple payment options and shipping methods available. The rental process should be flexible and easy to understand, with clear terms and conditions and transparent pricing.

        As it is a B2B e-commerce, you first need to register with the data that your company has provided you."
        link="https://smartrent.com.co/personas/"
      />
      {/* Herragro */}
      <Project
        isOpen={herragroIsOpen}
        closeModal={closeHerragro}
        img1={h1}
        img2={h2}
        img3={h3}
        title="Herragro"
        subTitle="E-Commerce"
        tag1="UX/UI"
        description="The complex e-commerce re-design for a modern multinational tool store in Latin America should incorporate advanced features such as blockchain technology and modern design to enable the sale of NFTs.

        The website should have a sleek and modern design, with advanced search and filtering options to help customers find the right tool.
        
        The product pages should include detailed information and rich media, as well as information on the NFTs' authenticity and provenance. The checkout process should be secure and flexible, with multiple payment options and international shipping available."
        link="https://www.herragro.com/shop"
      />
      {/* Bridge Baker */}
      <Project
        isOpen={bridgeBakerIsOpen}
        closeModal={closeBridgeBaker}
        img1={bb1}
        img2={bb2}
        title="Bridge Baker"
        subTitle="Bakery"
        tag1="SOCIAL MEDIA"
        tag2="SHOPIFY"
        description="The social media content creation for a modern bakery in London should showcase the bakery's delicious and innovative products using high-quality images and videos. The content should reflect the bakery's unique style and personality, while also engaging with customers and promoting special offers and events. The social media channels should be used to build a community around the bakery, with regular interactions and collaborations with other foodie influencers and brands.

        I also help the bakery with print design for events and with the design and organisation of the e-commerce that they use in Shopify."
        link="https://bridgebaker.co.uk/"
      />
      {/* & MORE */}
      <Project
        isOpen={moreIsOpen}
        closeModal={closeMore}
        img2={m1}
        img1={m2}
        img3={m3}
        img4={m4}
        img5={m5}
        title="& more..."
        subTitle="A bit of everything"
        tag1="BRANDING"
        tag2="FLYERS"
        tag3="PHOTO EDITING"
        description="As a designer, I been working with a different creative services including brand design, photo editing, and flyer design. My brand design services focus on creating unique visual identities for businesses and individuals, using a combination of typography, colour, and graphic elements to convey a brand's values and message. In photo editing, I use industry-standard software to enhance and retouch images, ensuring they look their best. Finally, my flyer design services cover everything from event promotions to product launches, using eye-catching visuals and persuasive messaging to create designs that grab attention and drive results."
      />
    </div>
  );
};

export default Projects;
